import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes } from "react-router-dom";
// import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { DashboardPage } from "./pages/dashboard-page";
// import { HomePage } from "./pages/home-page";
// import { NotFoundPage } from "./pages/not-found-page";
// import { ProfilePage } from "./pages/profile-page";
// import { ProtectedPage } from "./pages/protected-page";
// import { PublicPage } from "./pages/public-page";


export const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  return (
    <Routes>
        <Route index element={<DashboardPage />} />
        <Route path="/dashboard/" element={<DashboardPage />} />
      {/*<Route*/}
      {/*  path="/dashboard"*/}
      {/*  element={<AuthenticationGuard component={DashboardPage} />}*/}
      {/*/>*/}
      {/*<Route*/}
      {/*  path="/profile"*/}
      {/*  element={<AuthenticationGuard component={ProfilePage} />}*/}
      {/*/>*/}
      {/*<Route path="/public" element={<PublicPage />} />*/}
      {/*<Route*/}
      {/*  path="/protected"*/}
      {/*  element={<AuthenticationGuard component={ProtectedPage} />}*/}
      {/*/>*/}
      {/*<Route*/}
      {/*  path="/admin"*/}
      {/*  element={<AuthenticationGuard component={AdminPage} />}*/}
      {/*/>*/}
      <Route path="/dashboard/callback" element={<CallbackPage />} />
      {/*<Route path="*" element={<NotFoundPage />} />*/}
    </Routes>
  );
};
