import { useAuth0 } from "@auth0/auth0-react";
import * as React from 'react';
import dayjs, { Dayjs } from "dayjs";
import { Grid as GridTable, _ } from 'gridjs-react';
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from "@mui/material/InputLabel";
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Container from '@mui/material/Container';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PageLayout } from "../components/page-layout";
import {
    getGamesCall,
    getSportsCall,
    getSeasonsCall,
    getGameStatusesCall,
} from "../services/api-interractions.service";


export const DashboardPage: React.FC = () => {
    const { getAccessTokenSilently, isAuthenticated, getIdTokenClaims } = useAuth0();

    const [accessToken, setAccessToken] = useState('');
    const [userRoles, setUserRoles] = useState([]);

    const [games, setGames] = useState([]);
    const [sports, setSports] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [gameStatuses, setGameStatuses] = useState([]);

    const [selectedSport, setSelectedSport] = useState('fb');
    const [selectedSeason, setSelectedSeason] = useState('2022');
    const [selectedGameStatus, setSelectedGameStatus] = useState('');
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()))

    const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently();
        setAccessToken(accessToken);
    }

    const getUserRole = async () => {
        const idTokenClaims = await getIdTokenClaims();
        const userRoles = idTokenClaims['https://nextgen.bigten.org/roles']
        setUserRoles(userRoles);
    }

    const getGames = async () => {
        const { data, error } = await getGamesCall(accessToken, selectedDate, selectedSport,
                                                   selectedSeason, selectedGameStatus);
        setGames(data);
    };


    const getSports = async () => {
        const { data, error } = await getSportsCall(accessToken);
        setSports(data);
    }

    const getSeasons = async () => {
        const { data, error } = await getSeasonsCall(accessToken);
        setSeasons(data);
    };

    const getGameStatuses = async () => {
        const { data, error } = await getGameStatusesCall(accessToken, selectedSport)
        setGameStatuses(data);
    };

    useEffect(() => {
        getAccessToken().then(r => []);
    }, []);

    useEffect(() => {
        if (accessToken) {
            getUserRole().then(r => [])
        }
    }, [accessToken])

    useEffect(() => {
        if (accessToken) {
            getGames().then(r => []);
            getSports().then(r => []);
            getSeasons().then(r => []);
            getGameStatuses().then(r => []);
        }
    },[accessToken]);

    useEffect(() => {
        if (accessToken) {
            getGames().then(r => {});
        }
    }, [
        selectedSport,
        selectedSeason,
        selectedGameStatus,
        selectedDate,
        accessToken
    ]);

    return (
        <PageLayout>
            { (isAuthenticated && userRoles.includes("Dashboard user")) && (
                <div>
                    <br></br>
                    <br></br>
                    <Container component="main" style={{ maxWidth: "mb" }} sx={{ mb: 4 }}>
                        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {/* Sports popup menu */}
                            <Grid item xs={2}>
                                <FormControl style={{ minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-label">Sports</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={selectedSport}
                                        onChange={(event) => {
                                            event.preventDefault();
                                            setSelectedSport(event?.target?.value);
                                        }}>
                                        {sports.map((sport) => (
                                            <MenuItem key={sport.name} value={sport.abbr}>
                                                {sport.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/* Seasons popup menu */}
                            <Grid item xs={2}>
                                <FormControl style={{ minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-label">Seasons</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={selectedSeason}
                                        onChange={(event) => {
                                            event.preventDefault();
                                            setSelectedSeason(event?.target?.value);
                                        }}>
                                        <MenuItem value={''}>
                                            <em>All</em>
                                        </MenuItem>
                                        {seasons.map((season) => (
                                            <MenuItem key={season} value={season}>
                                                {season}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/* Game statuses popup menu */}
                            <Grid item xs={2}>
                                <FormControl style={{ minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-label">Game statuses</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={selectedGameStatus}
                                        onChange={(event) => {
                                            event.preventDefault();
                                            setSelectedGameStatus(event?.target?.value);
                                        }}>
                                        <MenuItem value={''}>
                                            <em>All</em>
                                        </MenuItem>
                                        {gameStatuses.map((gameStatus) => (
                                            <MenuItem key={gameStatus} value={gameStatus}>
                                                {gameStatus}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                      label="Select date to show"
                                      inputFormat="YYYY-MM-DD"
                                      value={selectedDate}
                                      onChange={(newValue) => {
                                          setSelectedDate(newValue)
                                      }}
                                      renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    variant="outlined"
                                    onClick={() => {setSelectedDate(undefined);}}
                                >Show all games by season</Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <GridTable
                        columns={[
                            {name: 'Game ID', width: '7%'},
                            {name: 'Date Time', width: '7%'},
                            {name: 'Home team', width: '7%'},
                            {name: 'Away team', width: '7%'},
                            {name: 'Game Status', width: '7%'},
                            {name: 'Pregame article status', width: '7%'},
                            {name: 'Postgame article status', width: '7%'}
                        ]}
                        data={() => {
                            return games.map(card => [
                                _(<a href="src/dashboard/components/pages/dashboard"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(card.game_url, '_blank');
                                    }}>{card.game_id}</a>),
                                card.date_time,
                                _(<a href="src/dashboard/components/pages/dashboard"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(card.home_team_url, '_blank');
                                    }}>{card.home_team_market}</a>),
                                _(<a href="src/dashboard/components/pages/dashboard"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(card.away_team_url, '_blank');
                                    }}>{card.away_team_market}</a>),card.status,
                                card.article_pregame_status, card.article_postgame_status
                            ])
                        }}
                        search={true}
                        sort={true}
                        // resizable={true}
                        pagination={{
                            enabled: true,
                            limit: 20,
                        }}
                        autoWidth={true}
                    />
                </div >
            )}
        </PageLayout>
    );
};
